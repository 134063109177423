:root {
  --wds-color-border: #e0dede;
  --wds-color-border-onaccent: rgba(161, 161, 161, 0.3);
  --wds-color-border-light: #fddddd;
  --wds-color-border-hover: #b3b3b3;
  --wds-color-border-disabled: #e0dede;
  --wds-color-border-danger: #d91921;
  --wds-color-border-danger-hover: #b90707;
  --wds-color-border-danger-focus: #b90707;
  --wds-color-border-danger-focus-light: #fedddd;

  --wds-color-bg: #ffffff;
  --wds-color-bg-hover: #ebebeb;
  --wds-color-bg-selected: #ebebeb;
  --wds-color-bg-focus: #e3e3e3;
  --wds-color-bg-light: #ebebeb;
  --wds-color-bg-strong: #e0dede;
  --wds-color-bg-strong-hover: #b3b3b3;
  --wds-color-bg-disabled: #f3f3f3;
  --wds-color-bg-disabled-light: #e0dede;
  --wds-color-bg-disabled-strong: #a9a7a7;
  --wds-color-bg-danger: #d91921;
  --wds-color-bg-danger-hover: #b90707;
  --wds-color-bg-filled: rgba(0, 0, 0, 0.04);
  --wds-color-bg-filled-hover: rgba(0, 0, 0, 0.06);

  --wds-color-icon: #858282;
  --wds-color-icon-disabled: #a9a7a7;
  --wds-color-icon-hover: #4b4848;

  --wds-color-text: #090707;
  --wds-color-text-danger: #d91921;
  --wds-color-text-light: #716e6e;
  --wds-color-text-disabled: #a9a7a7;
  --wds-color-text-disabled-light: #cac7c7;
}
