:root {
    --wds-screen-color-border: #E0DEDE;
    --wds-screen-color-border-onaccent: rgba(161, 161, 161, 0.3);
    --wds-screen-color-border-light: #FDDDDD;
    --wds-screen-color-border-hover: #B3B3B3;
    --wds-screen-color-border-disabled: #e0dedec7;
    --wds-screen-color-border-danger: #D91921;
    --wds-screen-color-border-danger-hover: #B90707;
    --wds-screen-color-border-danger-focus: #B90707;
    --wds-screen-color-border-danger-focus-light: #fedddd;

    --wds-screen-color-bg: rgba(255,255,255,0);
    --wds-screen-color-bg-hover: #ebebeb4f;
    --wds-screen-color-bg-selected: #EBEBEB;
    --wds-screen-color-bg-focus: #e3e3e3;
    --wds-screen-color-bg-light: #EBEBEB;
    --wds-screen-color-bg-strong: #E0DEDE;
    --wds-screen-color-bg-strong-hover: #B3B3B3;
    --wds-screen-color-bg-disabled: #f3f3f362;
    --wds-screen-color-bg-disabled-light: #E0DEDE;
    --wds-screen-color-bg-disabled-strong: #A9A7A7;
    --wds-screen-color-bg-danger: #D91921;
    --wds-screen-color-bg-danger-hover: #B90707;

    --wds-screen-color-icon: #858282;
    --wds-screen-color-icon-disabled: #A9A7A7;
    --wds-screen-color-icon-hover: #4B4848;

    --wds-screen-color-text: white;
    --wds-screen-color-text-danger: #D91921;
    --wds-screen-color-text-light: #716E6E;
    --wds-screen-color-text-disabled: #A9A7A7;
    --wds-screen-color-text-disabled-light: #CAC7C7;
}
