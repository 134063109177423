@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~design-system-old/build/css/design-system-old.css";
@import "~design-system/build/css/design-system.css";
@import "theme/colors.css";
@import "theme/defaultTheme.css";
@import "theme/wds.css";
@import "theme/pageplugDesign.css";

:root {
  --secondary: "#74BFD4";
  --primary: #27b7b7;
  --bright: #fff;
  --spacing: 24px;
  --radius: 10px;
}
blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFF !important;

  .taro-img__mode-aspectfit {
    vertical-align: top;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .ant-pro-sider-menu .anticon {
    font-size: 19px!important;
  }

  .ant-layout.ant-layout-has-sider {
    z-index: 1;
  }

  .ant-pro-sider-light {
    z-index: 1;
  }

  .ant-pro-basicLayout .ant-layout-header.ant-pro-header-light {
    z-index: 1!important;
  }

  .rc-tree-select-tree {
    border: none;
  }
  .rc-tree-select-dropdown {
    border: none;
  }
  .rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-switcher,
  .rc-tree-select-tree .rc-tree-select-tree-treenode span.rc-tree-select-tree-checkbox{
    background-image: none!important;
  }
  .rc-tree-select-single:not(.rc-tree-select-customize-input) .rc-tree-select-selector {
    padding: 0;
  }

  .bp3-portal {
    // dropdown portal in antd modal
    z-index: 99999;
  }

}

body.dragging *{
  cursor: grabbing !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

* {
  outline: none !important;
}

div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}

span.bp3-popover-target {
  display: flex;
  width: 100%;
}

span.bp3-popover-target > * {
  flex-grow: 1;
}

div.bp3-popover-arrow {
  display: none;
}

.bp3-button.bp3-loading {
  cursor: default !important;
}


.display-none {
  display: none;
}

.Toastify__toast {
  // padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.Toastify__progress-bar--default {
  background: rgb(3, 179, 101) !important;
}

.t--editor-appname-menu-portal {
  z-index: 9 !important;
}

.bp3-popover .bp3-input {
  outline: 0;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3 !important;
}

.rotate[data-v-075ccfd1] {
  -webkit-animation: rotating 0s linear infinite;
  animation: rotating 0s linear infinite;
}
@keyframes skill {
  0% {transform: rotate(0deg); box-shadow: none;}
  50% {box-shadow: 0 0 10px var(--primary), inset 0 0 20px var(--primary);}
  100% {transform: rotate(720deg); box-shadow: none;}
}

@keyframes skill_02 {
  0% {transform: rotate(0deg); box-shadow: none;}
  50% {box-shadow: 0 0 10px var(--secondary), inset 0 0 20px var(--secondary);}
  100% {transform: rotate(720deg); box-shadow: none;}
}

@keyframes float {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float2 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float3 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float4 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(60%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes float5 {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-60%);
  }
  100% {
    transform: translateY(0%);
  }
}

.bell {
  fill: #fff;
  position: absolute;
  top: 170px;
  left: 175px;
  -webkit-animation: ringing 2.5s ease infinite;
          animation: ringing 2.5s ease infinite;
}

.line {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
}

.line-1, .line-2, .line-3 {
  position: absolute;
  top: 170px;
  left: 230px;
}

.line-4, .line-5, .line-6 {
  position: absolute;
  top: 170px;
  right: 230px;
}

.line-1 {
  -webkit-animation: line-1 2.5s ease-out infinite both;
          animation: line-1 2.5s ease-out infinite both;
}

.line-2 {
  -webkit-animation: line-2 2.5s ease-out infinite both;
          animation: line-2 2.5s ease-out infinite both;
}

.line-3 {
  -webkit-animation: line-3 2.5s ease-out infinite both;
          animation: line-3 2.5s ease-out infinite both;
}

.line-4 {
  -webkit-animation: line-4 2.5s ease-out infinite both;
          animation: line-4 2.5s ease-out infinite both;
}

.line-5 {
  -webkit-animation: line-5 2.5s ease-out infinite both;
          animation: line-5 2.5s ease-out infinite both;
}

.line-6 {
  -webkit-animation: line-6 2.5s ease-out infinite both;
          animation: line-6 2.5s ease-out infinite both;
}

@-webkit-keyframes line-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%, 100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
            transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}

@keyframes line-1 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%, 100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
            transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-2 {
  0%, 19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%, 100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
            transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-2 {
  0%, 19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%, 100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
            transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-3 {
  0%, 39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
            transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-3 {
  0%, 39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: translate3d(60px, 0, 0) scale(2);
            transform: translate3d(60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%, 100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
            transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-4 {
  0% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  50%, 100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
            transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-5 {
  0%, 19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%, 100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
            transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-5 {
  0%, 19% {
    opacity: 0;
  }
  20% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  70%, 100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
            transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes line-6 {
  0%, 39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
            transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@keyframes line-6 {
  0%, 39% {
    opacity: 0;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: translate3d(-60px, 0, 0) scale(2);
            transform: translate3d(-60px, 0, 0) scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes ringing {
  0%, 50%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  5%, 10%, 15%, 20%, 30%, 35%, 40% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
  }
  45% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  7.5%, 12.5%, 17.5%, 22.5%, 27.5%, 32.5%, 37.5%, 42.5% {
    -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
  }
  47.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}
@keyframes ringing {
  0%, 50%, 100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  5%, 10%, 15%, 20%, 30%, 35%, 40% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg);
  }
  45% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  7.5%, 12.5%, 17.5%, 22.5%, 27.5%, 32.5%, 37.5%, 42.5% {
    -webkit-transform: rotate(-6deg);
            transform: rotate(-6deg);
  }
  47.5% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}
.bp-transparent {
  --wds-screen-color-text: #fafafa;
  & >* span, .bp3-button {
    // color: var(--wds-screen-color-text) !important;
    // background-color: var(--wds-screen-color-bg) !important;
  }
  background-color: var(--wds-screen-color-bg) !important;
  color: var(--wds-screen-color-text) !important;
  & >* .bp3-input-group {
    background-color: var(--wds-screen-color-bg) !important;
    input, textarea , button{
      background-color: var(--wds-screen-color-bg) !important;
      color: var(--wds-screen-color-text) !important;
    }
  }
  & >* .ant-card {
    background-color: var(--wds-screen-color-bg) !important;
  }
  & >* .ant-card-head {
    color: var(--wds-screen-color-text) !important;
  }
  div[data-testid="switchgroup-container"] {
    color: var(--wds-screen-color-text);
  }
}

.bp3-popover .bp3-input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.layout-control.bp3-popover.bp3-minimal {
  margin-top: 8px !important;
}

.bp3-popover.none-shadow-popover {
  box-shadow: none;
  border: 0px;
}

.bp3-datepicker {
  border-radius: 0 !important;
}

/* making both the Modal layer and the Dropdown layer */
.bp3-modal-widget, .appsmith_widget_0 > .bp3-portal {
  z-index: 2 !important;
}

/* Portals on the Modal widget */
.bp3-modal-widget .bp3-portal {
  z-index: 21 !important;
}

.bp3-overlay-backdrop {
  background-color: rgba(16, 22, 26, 0.7) !important;
}

.bp3-popover, .bp3-popover2, .bp3-popover2 .bp3-popover2-content, .bp3-popover .bp3-popover-content {
  border-radius: 3px !important;
}

.bp3-dialog {
  border-radius: 8px !important;
}

.bp3-overlay .bp3-overlay-content {
  border-radius: 5px !important;
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  font-family: auto!important;
}

.full-height-form {
  height: 100%;

  & > form {
    height: 100%;
  }
}

.designable-drawer {

  .dn-aux-helpers button {
    font-size: 14px !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 3px !important;
    height: 20px !important;
    color: var(--dn-aux-selector-btn-color) !important;
    background: var(--dn-aux-selector-btn-bg-color) !important;
    border-color: var(--dn-aux-selector-btn-border-color) !important;
  }

  .dn-main-panel.root {
    height: auto;
    top: 57px;
    bottom: 49px;
  }

  .dn-light {
    --dn-brand-color: var(--ads-color-brand);
    --dn-brand-hovering: var(--ads-color-brand-hover);
    --dn-brand-dragging: rgba(0, 0, 0, 0.26);
    --dn-brand-dropping: rgba(0, 0, 0, 0.34);
    --dn-brand-moving: rgba(0, 0, 0, 0.5);
  }
}

/* react-sortable-tree styles */
.pageplug-rst {
  .rstcustom__expandButton {
    background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+") no-repeat center;
  }
  .rstcustom__collapseButton {
    background: #fff url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==") no-repeat center;
  }
  .rstcustom__rowContents {
    box-shadow: none;
    cursor: grab;
    border: 1px solid var(--ads-color-brand);
    border-radius: 4px;
    background: var(--ads-color-background-secondary);
  }
  .rstcustom__rowWrapper {
    padding: 10px 10px 10px 0;
    .rstcustom__rowLandingPad::before {
      border: 3px dashed var(--ads-color-brand);
      background: var(--ads-color-background-secondary);
      border-radius: 4px;
    }
    .rstcustom__rowCancelPad::before {
      border: 3px dashed #ff4d4f;
      background: #ffccc7;
    }
  }
  .rst__placeholder::before {
    z-index: 0;
  }
  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after,
  .rstcustom__lineChildren::after {
    background-color: #c7c7c7;
  }
  .rstcustom__rowWrapper:hover {
    opacity: 1;
    .rstcustom__rowContents {
      background: #fff;
      box-shadow: 0 0 0 4px var(--ads-color-background-secondary);
    }
  }
  .icon-select-container {
    display: inline-block;
  }
  .rst__placeholder {
    max-width: unset;
    &::before {
      left: 20px;
      right: 20px;
    }
    &.rst__placeholderLandingPad::before {
      border: 3px dashed var(--ads-color-brand);
      background: var(--ads-color-background-secondary);
      border-radius: 4px;
    }
    &.rst__placeholderCancelPad::before {
      border: 3px dashed #ff4d4f;
      background: #ffccc7;
    }
  }
  .rstcustom__toolbarButton {
    margin-right: 5px;
  }
}

::selection {
  color: var(--ads-color-brand-text) !important;
  background: var(--ads-color-brand) !important;
}

.BMap_cpyCtrl {
  display: none!important;
}

.anchorBL {
  display: none!important;
}

.expandedWrap {
  > td {
    background-color: #fff !important;
  }
}
.jsonViewWrap {
  min-height: 28px;
  padding: 8px 30px 8px 8px;
  background-color: rgb(255, 255, 255);
  position: relative;
  border: 1px solid rgb(179, 179, 179);
}

.auditTable {
  .ant-table-thead {
    height: 40px;
    position: sticky;
    top: 48px;
    z-index: 100;
  }
}

.auditTableRow {
  cursor: pointer;
  > td {
    padding: 8px !important;
  }
}
.auditForm {
  .ant-form-item {
    margin-bottom: 8px !important;
  }
}

.ant-form-item-control-input > .ant-form-item-control-input-content > div.ant-input-number {
  &:hover, &:focus, &:focus-within {
    border-color: #4096ff;
  }
}

div.rc-select-show-arrow.rc-select-multiple .rc-select-selector {
  height: auto;
}

.rc-select-selection-overflow-item span.ads-v2-text {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-wrap: break-word; /* 兼容性设置，用于处理长单词或URL的换行 */
}

.pageplug-menu-editor {
  ::selection {
    color: var(--ads-color-brand-text);
    background: var(--ads-color-brand);
  }
  .my-icon {
    margin-left: 5px;
    margin-top: -23px;
    padding: 0.5px;
    font-size: 0.85rem;
    color: #c7c7c7;
    background-color: #000;
    border: 1px solid #fff;
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .my-tree {
    margin: 2rem 0;
  }
  
  .hidden {
    display: none;
  }

  .page_pool,
  .menu_pool {
    margin: 16px;
    // border: 1px solid;
    ul {
      list-style: none;
      padding-inline-start: 19px;
      li {
        width: 30rem;
        .bp3-tree-node-content {
          height: inherit !important;
        }
        .bp3-tree-node-content:hover {
          background-color: transparent !important;
        }
      }
    }
  }
  
  .page_pool {
    .menuitem {
      border-width: 1px;
    }
    padding: 40px 10px;
    ul {
      li {
        width: 26rem;
      }
    }
  
    min-height: 100px;
  }
  .menu_pool {
    padding: 40px 10px;
    .bp3-tree-root {
      padding-bottom: 50px !important;
      padding-bottom: 10px !important;
    }
    ul {
      li {
        display: block;
        position: relative;
        padding-left: calc(var(--spacing) - var(--radius) - 2px);
        border-left: 1px solid #d9d9d9;
      }
  
      li:last-child {
        border-color: transparent;
      }
  
      li::before {
        content: "";
        display: block;
        position: absolute;
        top: calc(var(--spacing) / -20);
        left: -1px;
        width: calc(var(--spacing) - 11px);
        height: calc(var(--spacing) + 1px);
        border: 1px solid #d9d9d9;
        border-width: 0 0 1px 1px;
      }
    }
  }
  .menu_item {
    display: flex;
  }
  .arrowitem {
    // border: 1px solid rgb(26, 82, 185);
    width: 14px;
    height: 14px;
    position: absolute;
    left: -7px;
    top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    .bp3-tree-node-caret {
      padding: 0px;
      transform: none;
    }
    .bp3-tree-node-caret,
    .bp3-tree-node-caret-none {
      min-width: 0px;
    }
  }
  .menuicon,
  .menuiconfix {
    span {
      border-radius: 4px;
      padding: 2px;
      margin-right: 10px;
    }
    span:hover {
      background-color: #d9d9d9;
    }
  }
  
  .menuhide {
    background: #eeeeee !important;
  }
  .hide {
    display: none;
  }
  .menuitem {
    padding: 8px 0px;
    padding-left: 10px;
    width: 100%;
    border: 1px solid #d9d9d9;
    margin: 4px 0px;
    cursor: pointer;
    border-radius: 4px;
    transition: all linear 0.1s;
    display: flex;
    align-items: center;
    .menuicon {
      visibility: hidden;
    }
    .menuiconfix {
      visibility: visible;
    }
    &:hover:not(.menuhide) {
      background-color: rgb(190 224 230 / 33%) !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      .menuicon {
        visibility: visible;
      }
    }
  }
  .menufold {
    background: linear-gradient(
    45deg,
    rgba(255,255,255,0) 93%,
    rgb(190 224 230 / 33%) 0, rgb(190 224 230 / 33%) 96%,
    rgba(255,255,255,0) 0, rgba(255,255,255,0) 100%
    );
    border-style: dashed;
    // border-color: var(--primary-color);
  }
  .menuDraggingOver {
    border-color: #13c2c2;
    background-color: #fff;
  }
  
  .page_list {
    display: flex;
    padding-left: 0px;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 20px;
  }
  .extra {
    position: absolute;
    right: 0px;
  }
  
  .menu_pool {
    :global {
      .bp3-tree-root {
        padding: 20px 0px !important;
      }
      .bp3-tree-node-content {
        height: inherit !important;
        padding-left: 0px !important;
      }
      .bp3-tree-node-content:hover {
        background-color: transparent;
      }
    }
    .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
      background-color: #fff;
      color: #13c2c2;
    }
    [class*="bp3-tree-node-content-"] {
      padding-left: 0px !important;
    }
  }

  .icon-select {
    width: 30px;
    position: relative;
    > .rc-select-selector {
      width: 30px;
      min-width: 0px;
    }
  }  
}


.custom-expand-icon {
  display: flex;
  align-items: center;
}
.ant-menu-submenu.ant-menu-submenu-vertical {
  .custom-expand-icon {
    visibility: hidden;
  }
}
.ant-menu-submenu > .ant-menu-submenu-title {
  // display: flex !important;
}

.ant-menu-submenu-popup.ant-menu-submenu {
  > ul {
    color: rgba(0, 0, 0, 0.65) !important;
  }
}


.ant-menu-submenu-popup.ant-menu-submenu.ant-menu-dark {
  > ul {
    color: white !important;
  }
}


.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, .ant-menu-dark>.ant-menu .ant-menu-item-selected {
  color: white !important;
}

.ant-menu-submenu-title {
  transition: opacity 0.1s, background 0.1s, color 0s !important;
}
.ant-menu-submenu-popup >.ant-menu .ant-menu-item, 
.ant-menu-submenu-popup >.ant-menu .ant-menu-submenu-title {
  transition: opacity 0.1s, background 0.1s, color 0s !important;
}

.ant-menu-submenu-popup >.ant-menu .ant-menu-item .ant-menu-item-icon +span, 
.ant-menu-submenu-popup >.ant-menu .ant-menu-submenu-title .ant-menu-item-icon +span, 
.ant-menu-submenu-popup >.ant-menu .ant-menu-item .anticon +span, 
.ant-menu-submenu-popup >.ant-menu .ant-menu-submenu-title .anticon +span {
  transition: opacity 0.1s, background 0.1s, color 0s !important;
}

.ant-menu .ant-menu-item, 
.ant-menu .ant-menu-submenu-title {
  transition: border-color 0.1s, background 0.1s, padding 0.1s !important;
}

.custom-breadcrumb {
  margin-top: 9px;
}
.pp-height {
  input, button {
    height: 48px !important;
  }
}
.pp-font span.ads-v2-button__content-children {
  height: 28px;
  font-family: Source Han Sans CN VF;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.5em;
  text-align: center;
  text-indent: 0.5em;
}

.taroify-icon--inherit {
  font-size: 16px !important;
  transform: translateY(1px);
}

// css bug
.bp3-datepicker .DayPicker-Day:focus {
  color: #ffffff !important;
}

.normal-editor {
  .CodeMirror-scroll {
    max-height: 500px;
    overflow-y: auto;
  }
}
.rjsf {
  div.ant-row-end:has(>div>button.add-button) {
    width: calc(100% - 192px + 24px);
    margin-top: 20px;
    & > div {
      flex: none !important;
      width: 100%;
    }
    .add-button {
      width: 100%;
    }
  }
}
@primary-color: var(--ads-color-brand);