/* nunito-sans-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./nunito-sans-v12/nunito-sans-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* nunito-sans-300italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 300;
  src: url("./nunito-sans-v12/nunito-sans-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* nunito-sans-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./nunito-sans-v12/nunito-sans-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* nunito-sans-italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 400;
  src: url("./nunito-sans-v12/nunito-sans-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* nunito-sans-700 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./nunito-sans-v12/nunito-sans-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* nunito-sans-700italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Nunito Sans";
  font-style: italic;
  font-weight: 700;
  src: url("./nunito-sans-v12/nunito-sans-v12-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
