/* montserrat-300 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-300italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-300italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-regular - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-regular.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-500 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-500.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-500italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-500italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-700 - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* montserrat-700italic - cyrillic_cyrillic-ext_latin_latin-ext_vietnamese */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("./montserrat-v25/montserrat-v25-cyrillic_cyrillic-ext_latin_latin-ext_vietnamese-700italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
