/* poppins-300 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-300.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-300italic - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-300italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-regular - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-regular.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-italic - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-500 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-500.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-500italic - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-500italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-700 - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-700.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
/* poppins-700italic - devanagari_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: url("./poppins-v20/poppins-v20-devanagari_latin_latin-ext-700italic.woff2")
    format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+ */
}
